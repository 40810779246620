const MenuList=[
    {
        id:"1",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/mango-4991704-4153039.png",
        category:"mango",
        price:"12 Rs. ",
        name:"mango",
        description:"Some quick example text to build on the card.",
    },
    {
        id:"2",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/mango-4463395-3703438.png",
        category:"mango",
        price:"12 Rs. ",
        name:"mango",
        description:"Some quick example text to build on the card . ",
    },
    {
        id:"3",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/mango-4452961-3688402.png",
        category:"mango",
        price:"12 Rs. ",
        name:"mango",
        description:"Some quick example text to build on the card . ",
    },
    {
        id:"4",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/mango-5663225-4718162.png",
        category:"mango",
        price:"12 Rs. ",
        name:"mango",
        description:"Some quick example text to build on the card . ",
    },
    {
        id:"5",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/mango-4889653-4079100.png",
        category:"mango",
        price:"12 Rs. ",
        name:"mango",
        description:"Some quick example text to build on the card . ",
    },
    {
        id:"6",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/mangosteen-4991688-4153023.png",
        category:"mangosteen",
        price:"12 Rs. ",
        name:"mangosteen",
        description:"Some quick example text to build on the card . ",
    },
    {
        id:"7",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/mangosteen-5644359-4695830.png",
        category:"mangosteen",
        price:"12 Rs. ",
        name:"mangosteen",
        description:"Some quick example text to build on the card . ",
    },
    {
        id:"8",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/mangosteen-4991688-4153023.png",
        category:"mangosteen",
        price:"12 Rs. ",
        name:"mangosteen",
        description:"Some quick example text to build on the card . ",
    },
    {
        id:"9",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/papaya-5529027-4725757.png",
        category:"Papaya",
        price:"12 Rs. ",
        name:"papaya",
        description:"Some quick example text to build on the card . ",
    },
    {
        id:"10",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/papaya-5527713-4725776.png",
        category:"Papaya",
        price:"12 Rs. ",
        name:"papaya",
        description:"Some quick example text to build on the card . ",
    },
    {
        id:"11",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/papaya-4463393-3703436.png",
        category:"Papaya",
        price:"12 Rs. ",
        name:"papaya",
        description:"Some quick example text to build on the card . ",
    },
    {
        id:"12",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/orange-4033050-3345776@0.png",
        category:"Orange",
        price:"12 Rs. ",
        name:"orange",
        description:"Some quick example text to build on the card . ",
    },
    {
        id:"13",
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/orange-4991695-4153030.png",
        category:"Orange",
        price:"12 Rs. ",
        name:"orange",
        description:"Some quick example text to build on the card . ",
    },


]

export const categories = [
    {
        category : "mango",
    },
    {
        category : "Papaya",
    },
    {
        category : "mangosteen",
    },
    {
        category : "Orange",
    },
   
] 
export default MenuList;