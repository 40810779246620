import React from "react";
import Common from "../Common";

const About=()=>{
    let About_img = "https://cdni.iconscout.com/illustration/premium/thumb/artificial-intelligence-isometric-1739202-1479090.png"

    return( 
    <Common name="Welcome To About Page" Imgsrc = {About_img}  visite="/contract" btName="Contract us" />
    );
}
export default About;