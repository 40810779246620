import React, {  useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import axios from "axios";

const Contract = () => {
    var [data ,setData] = useState({
        fullname:"",
        phone:"",
        email:"",
        password:"", 
    });
    let history = useNavigate();
    const InputEvent=(e)=>{
        const {name , value}=e.target; 
        setData((preVal)=>{
            return{
                ...preVal,
                [name]:value,
        }
      })
    }

  // useState(()=>{
  //   const newdata = JSON.parse(localStorage.getItem("userDetails"));
  //       array.push(newdata) ;
  // },[])

  

    const FormSubmit=(e)=>{
      // if (data.email == null || data.email == "", b == null || b == "", c == null || c == "", d == null || d == "") {
      //   alert("Please Fill All Required Field");
      //   return false;
      // }else{
        
      // }


      let Arr = [];
      let collection = [];
      Arr.push({name:data.fullname,email:data.email,phone:data.phone,password:data.password});
      var personDetails = localStorage.getItem("userDetail");
      if (personDetails === null || Object.values(personDetails).length === 0) {
        localStorage.setItem("userDetail" , JSON.stringify(Arr));
         axios.post("https://sheet.best/api/sheets/92e82bd6-2503-4de6-bc05-37646c233f03", Arr).than((response)=>{
          console.log(response)
         }) 
        // setData(true);
        // data.show("Bookmark successfully.", data.SHORT);
      } else {
        collection = JSON.parse(personDetails);
        var isdata =  collection.filter(
          (item, index) =>
          item.name === data.name && item.email === data.email 
        );

        if (isdata.length === 0) {
           collection.push({name:data.fullname,email:data.email,phone:data.phone,password:data.password});
           localStorage.setItem("userDetail",JSON.stringify(collection));
           console.log("stores Data")
           alert("succes Fully Submit Form")
           history("/login", { replace: true });
        
        } else {
          console.log("ail")
        } 
    //     e.prevantDefault();
    //     const newdata = data;
    //     const oldDta = JSON.parse(localStorage.getItem("userDetails"));
    //     array.push (oldDta);
    //     localStorage.setItem("userDetails" , JSON.stringify([...array,data]));
    //     alert(`My name is ${data.fullname}`)
    //    console.log({data});

    // login condition 
  

    }};
  return (
    <>
      <section className="contract_us">
      <div className="container">
        <div className="row flex-row-reverse align-items-center">
          <div className="col-md-6 col-sm-6 col-xs-12 ">
          <div className="my-3 ">
          <h2 className="text-center">Contract Us</h2>
        </div>
            <form onSubmit={FormSubmit}>
                <div className="mb-3">
                  <label  className="form-label">
                      Fullname
                  </label>
                <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    name="fullname"
                    value={data.fullname}
                    onChange={InputEvent}
                    required
                    placeholder="Enter your name"
                />
                </div>
                <div className="mb-3">
                    <label  className="form-label">
                        Phone
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        id="exampleFormControlInput2"
                        name="phone"
                        value={data.phone}
                        onChange={InputEvent}
                        required
                        placeholder="Mobile Number"
                     />
                </div>
                <div className="mb-3">
                <label  className="form-label">
                    Email address
                </label>
                <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput3"
                    name="email"
                    value={data.email}
                    onChange={InputEvent}
                    required
                    placeholder="name@example.com"
                />
                </div>
                <div className="mb-3">
                <label  className="form-label">
                  Enter Password
                </label>
                <input
                    className="form-control"
                    id="exampleFormControlTextarea5"
                    rows="3"
                    name="password"
                    required
                    placeholder="password"
                    value={data.password}
                    onChange={InputEvent}
                />
                <div className=" mt-4 ">
                    
                    <Button variant="outlined" className="d-block m-auto"  type="submit">
                      Disabled
                    </Button>
                </div>
                </div>
            </form>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 ">
            <div className="cont_img">
              <img src="https://cdn3d.iconscout.com/3d/premium/thumb/contact-us-5463949-4568180.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  );
};
export default Contract;
