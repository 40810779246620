import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import {NavLink } from 'react-router-dom'

const Login = () => {
    const [data ,setData] =useState({
        email:"",
        password:"",  
        showpassword:false,
    });
    const [getData ,setgetData] = useState(false)
    const [showPassword , setShowPassword] = useState(false);
    let history = useNavigate();
    const InputEvent=(e)=>{
        const {name , value}=e.target; 
        
        setData((preVal)=>{
            return{
                ...preVal,
                [name]:value,
        }
    })
   
    }
    // const togglePassword = (elem) =>{
    //   setgetData({
    //     ...data,
    //     showPassword: !data.showpassword,
    //   });
      
    // }
    useEffect(()=>{  
         console.log("hello");
    },[getData])

    const FormSubmit=(e)=>{
       
      e.preventDefault();
      console.log("loinn work")
      
      const entry = JSON.parse(localStorage.getItem("userDetail"));
      
      const results =  entry.filter((item , index) => {
        return item.email === data.email && item.password === data.password })
    
      if(results.length > 0 )
          {
            alert( `You have successfully logged in. My name is ${data.email} ${data.password}`)
            localStorage.setItem("user", results[0].name); 
            setgetData(false)
            history("/service", { replace: true });
              window.location.reload()
            

           } else {
            alert(" pls filled the details properly")
           }
          }
    //   for (const elem of entry){
    //     if(data.email === elem.email && data.password === elem.password && getData == false )  
    //     {
    //       setgetData(true)
    //       break;     
    //     }
    //   }
    //     if(getData)
    //     {
    //       alert( `You have successfully logged in. My name is ${data.email} ${data.password}`)
    //       localStorage.setItem("user", data.name); 
    //      } else {
    //       // setgetData(false)
    //       alert(" pls filled the details properly")
    //      }
    //   console.log(entry);
    // }    
  return (
    <section className="w-100 p-4 d-flex justify-content-center pb-4">
      <form onSubmit={FormSubmit} style={{ width: "22rem" }}>
        <div className="form-outline mb-4">
        <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">
                      Email
                  </label>
                <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    name="email"
                    value={data.email}
                    onChange={InputEvent}
                    placeholder="Enter your name"
                 />
                </div>
                <div className="mb-3 inPassword">
                    <label for="exampleFormControlInput2" className="form-label">
                      password
                    </label>
                    <input
                        type= {showPassword ? "text" : "password"}
                        className="form-control"
                        id="exampleFormControlInput2"
                        name="password"
                        value={data.password}
                        onChange={InputEvent}
                        placeholder="Enter Password"  

                    />
                     <button className="hideShowBtn" onClick={(e) => {
                       e.preventDefault()
                        showPassword ? setShowPassword(false) : setShowPassword(true)    
                        } }>
                      { showPassword ? <VisibilityOffIcon /> : <VisibilityIcon/> }
                    </button>
                    {/* <button className="btn btn-outline-primary" ></button> */}
                   
                   
                
                </div>
        </div>  
        <div className=" mt-4 ">
            <button className="btn btn-primary d-block mx-auto" type="submit">
                LOGIN
            </button>
        </div>
      </form>

    </section>
  );
};

export default Login;
