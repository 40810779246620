import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import ShoppingCart from './Components/shoppingCart/ShoppingCart';
// import Login from './Components/LoginAndSignin/login';
// import Weather from './Components/weather/Weather';
// import TodoList from './Components/todo_list/TodoList';
// import Food from './Components/Food/Food';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import {BrowserRouter} from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    {/* <ShoppingCart/> */}
    {/* <Login/> */}
    {/* <Food/> */}
    <App/>
    {/* <TodoList/> */}
    {/* <Weather/> */}
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
