import React from "react";
import { NavLink } from "react-router-dom";

const Common=(props)=>{
    return( 
       <section className="" id="Common_block">
          <div className="container">
             <div className="row align-items-center">
                <div className="col-md-6 pt-5 pt-lg-0 ">
                   <h2>{props.name} <strong className="brand-name">Shrmnoday</strong></h2>
                   
                   <h5 className="my-3 ">We are the team of talented developer making website</h5>
                   <div className="mt-3 get-start-btn">
                    <NavLink to={props.visite} className="btn btn-primary">{props.btName}</NavLink>
                   </div>
                </div>
                <div className="col-md-6 pt-5 pt-lg-0">
                   <img src={props.Imgsrc} className="img-fluid bounce-top d-block mx-auto" alt="Common img" />
                </div>
             </div>
          </div>
       </section>
    );
}
export default Common;