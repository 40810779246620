import React from "react";
import Card from "./Card";
import Sdata from "./Sdata";
const Service = () => {
  return (
    <section>
      <div className="container ">
        <div>
          <div className="my-5 ">
            <h2 className="text-center">Our Services</h2>
          </div>
        </div>
        <div className="card_gallery">
          <div className="row gy-5 ">
          {
            Sdata.map((val ,index)=>{
            return(
                <Card key={val.id} imgsrc={val.imgsrc} title={val.title} BodyContent={val.content} visite={val.Site_link} btname={val.btname} />
             );
            })
          }
          </div>
        </div>
      </div>
    </section>
  );
};
export default Service;
