import React, {useEffect,useState } from "react";
// import Request from 'axios-react';
// import axios from "axios";
import "./css/style.css";
const Weather = () => {
  // const ApiKey="a19ffc3e7276e234e878e69182ed67ef";
  const [city, setCity] = useState(false);
  const [search, setSearch] = useState('Indore');
  const [temperatureColor, setTemperatureColor] = useState('hot');
  const [country , setCountry] = useState("IN")
  const fatchApi = async () => {
    const apiURl = `https://api.openweathermap.org/data/2.5/weather?q=${search}&units=metric&appid=a19ffc3e7276e234e878e69182ed67ef`;
    const response = await fetch(apiURl);
    const resJson = await response.json();
    const sunrise = new Date(resJson.sys.sunrise).toLocaleString();
    console.log(sunrise);
    console.log(resJson);
    setCity(resJson);
    setTemperatureColor(resJson.weather[0].main);
  // full Country Name 
  const regionNames = new Intl.DisplayNames(
    [country], {type: 'region'}
  );
  setCountry(regionNames.of(city.sys.country))
};
  useEffect(() => {
    fatchApi();
}, []);

   
// else if(temp > 5 && temp <= 10)  

  // https://api.openweathermap.org/data/2.5/weather?q=mumbai&appid=a19ffc3e7276e234e878e69182ed67ef
  // const getWeatherDeatils =(cityName)=>{
  //         if(!cityName) return;
  //         const apiURl="https://api.openweathermap.org/data/2.5/weather?q=" + cityName + "&appid=" + ApiKey
  //         axios.get(apiURl).than((res)=>{
  //             console.log("response",res);
  //         }).catch((err)=>{
  //             console.log("err",err);
  //         })
  //  }
  // useEffect(()=>{
  //    getWeatherDeatils("mumbai");
  // },[])
  return (
    
    <>
      <div className=" box ">
      <div className="box-container" style={{
        backgroundImage : temperatureColor  === "Snow" ? `url(https://www.icegif.com/wp-content/uploads/snow-icegif-29.gif)` : temperatureColor === "Clouds" ? `url(https://i.pinimg.com/originals/64/8f/0f/648f0f2dad03c3a0adbbda57c7df7e60.gif)` : temperatureColor === "Haze" ? `url(https://c.tenor.com/v1yY5NqZF2kAAAAM/landscape-fog.gif)` : temperatureColor === "Rain" ? `url(https://i.pinimg.com/originals/b1/cd/36/b1cd36614ed294ba79e0eb774b7742d4.gif)`  : "blue"
      }}>
      <div>
        <h1 className="text-center">Weather</h1>
      </div>
        <div className="">
          <input type="sear ch"  className="inputFeild" onChange={(event) => {setSearch(event.target.value); }}/>
          <button onClick={(event)=>{
               fatchApi()
          }}>city</button>  
        </div>
        {!city ? (<p className="Error text-center pt-3 text-danger">No data Found </p>) : (<div className="info">  
            <h2 className="locatin pt-3"><img alt="weather" src="https://cdn.iconscout.com/icon/free/png-64/weather-1656157-1407896.png" /> {city.name },{country}</h2>
            <div className="Temprature">
            <h3 className="pt-2">{city.main.temp} <span>&deg;</span>cel </h3>
            <p className="min_max_tenp"><strong>Min</strong> : {city.main.temp_min} <span>&deg;</span>cel | <strong>Max</strong>: {city.main.temp_max} <span>&deg;</span>cel </p>
            </div>
            <div className="sunrisr_snd_sunset">
              <div className="sunrise">
                <span><img src="https://cdn3d.iconscout.com/3d/premium/thumb/sunrise-5294221-4431746.png" alt="Sunrise"></img></span>
                <h5> Sunrise</h5>
                <p>{new Date(city.sys.sunrise * 1000).toLocaleTimeString()}</p>
            </div>
              <div className="sunrise">
              
              <span> 
                <img src="https://cdn.iconscout.com/icon/premium/png-64-thumb/sunset-93-391157.png " alt="sunset"/>
               </span>
                <h5> Sunset</h5>
                <p>{new Date(city.sys.sunset * 1000).toLocaleTimeString()}</p>
              </div>
            </div>
            <div className="description">
              <p> description : {city.weather[0].description} </p> 
            </div>
        </div>)}
   
      </div>
      </div>
    </>
  );
};
export default Weather;


// if (newTemperature < 15) {
//   setTemperatureColor('cold');
// }
// if (newTemperature >= 15) {
//   setTemperatureColor('hot');
// }
//url
//https://www.youtube.com/watch?v=73e6wTyxbSg
//https://www.youtube.com/watch?v=A_Loyzrg50Y
