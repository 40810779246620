import React from "react";
import Common from "../Common";

const Home=()=>{
    let Home_img="https://cdni.iconscout.com/illustration/premium/thumb/database-server-isometric-1739199-1479084.png";
    
    return( 
       <Common name="Grow your business with" Imgsrc={Home_img} visite="/service" btName="Serviceses"/>
    );
}
export default Home;