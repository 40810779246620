const Sdata = [
    {
        id:1,
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/rainy-weather-4034172-3337336@0.png",
        title: "Weather",
        content:" Some quick example text to build on the card title and make up the bulk of the card's content.",
        Site_link: "/weather",
        btname:"Weather",
    },
    {
        id:2,
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/notepad-and-ballpoint-5457384-4560302.png",
        title: "Note Pad",
        content:" Some quick example text to build on the card title and make up the bulk of the card's content.",
        Site_link: "/notepad",
        btname:"NotePad",
    },
    {
        id:3,
        imgsrc:"https://cdn3d.iconscout.com/3d/premium/thumb/fruits-5421948-4528669.png",
        title: "Web designer",
        content:" Some quick example text to build on the card title and make up the bulk of the card's content.",
        Site_link: "/foods",
        btname:"Choose Foods",
    },
    {
        id:4,
        imgsrc:"https://cdni.iconscout.com/illustration/premium/thumb/data-cleansing-isometric-1739198-1479087.png",
        title: "Web designer",
        content:" Some quick example text to build on the card title and make up the bulk of the card's content.",
        Site_link: "/contract",    
        btname:"Read More",
    },
    {
        id:5,
        imgsrc:"https://cdni.iconscout.com/illustration/premium/thumb/data-cleansing-isometric-1739198-1479087.png",
        title: "Web designer",
        content:" Some quick example text to build on the card title and make up the bulk of the card's content.",
        Site_link: "/contract",   
        btname:"Read More",
    },
    {
        id:6,
        imgsrc:"https://cdni.iconscout.com/illustration/premium/thumb/data-cleansing-isometric-1739198-1479087.png",
        title: "Web designer",
        content:" Some quick example text to build on the card title and make up the bulk of the card's content.",
        Site_link: "/contract",  
        btname:"Read More",
    },
    {
        id:7,
        imgsrc:"https://cdni.iconscout.com/illustration/premium/thumb/data-cleansing-isometric-1739198-1479087.png",
        title: "Web designer",
        content:" Some quick example text to build on the card title and make up the bulk of the card's content.",
        Site_link: "/contract",  
        btname:"Read More",
    },
    {
        id:8,
        imgsrc:"https://cdni.iconscout.com/illustration/premium/thumb/data-cleansing-isometric-1739198-1479087.png",
        title: "Web designer",
        content:" Some quick example text to build on the card title and make up the bulk of the card's content.",
        Site_link: "/contract",
        btname:"Read More",
    },
]
export default Sdata;