import React, { useState } from 'react';

const TodoList = () => {
    const [item ,setitem] =useState("apple");
    const [data ,setdata]=useState([]);
    const [toggleSubmit ,settoggleSubmit] = useState(true);
    const [isEditIten ,setisEditIten ]=useState(null)
    
    const addItem = () =>{
        if(!item){
          alert("please fill the data")
        }  else if(item && !toggleSubmit ){
           setitem( 
            data.map((elem)=>{
              if(elem.id === isEditIten){
                return{ ...elem , name: item}
              }
              return elem 
            })
           )  
        settoggleSubmit(true)
        setitem("");
        setisEditIten(null) 
        } 
        else{  
        const allInputData = {id: new Date().getTime().toString() , name: item }    
        setdata([...data , allInputData]);
        setitem('') }
    }

    const deleteItem = (index) =>{
        // console.log(id) 
        const updatedItem =data.filter((elem)=>{
         return  index !== elem.id;

        })
        setdata(updatedItem);

    }
    const removeAll=()=>{
        setdata([]);
    }
    const editItem=(id)=>{
        var newEditItem = data.find((elem)=>{
        return elem.id  === id;
        }) 
    console.log(newEditItem);
    settoggleSubmit(false)
    setitem(newEditItem.name);
    setisEditIten(id) 
    }

  return (
    <div className=" box ">
        <div className='box-container'>
            <figure className='image  '>
             <img className='d-block mx-auto' src="https://cdn3d.iconscout.com/3d/premium/thumb/list-3711847-3105321.png" alt=" list" width="60" height="100" />
             <figcaption><p className='text-center'>  TODO LIST 📑</p> </figcaption>  
            </figure>
            
            <div className='todo_cont'>
                <input className="inputFeild" type="text" value={item} onChange={(event)=>{setitem(event.target.value)}} placeholder=' ✍️ Add Item...' /> 
                {/* <i className="fa-solid fa-pen-to-square edit-btn"></i> */}
                {toggleSubmit ? <button title="add item" onClick={addItem}>Add</button> : <button title="Edit item" onClick={addItem}>Edit</button>}
                
            </div>
            <div className='show_item'>
                {
                    data.map((elem)=>{
                        return(
                            <div className='eachitem inputFeild' key={elem.id}>
                              <h4 className='mt-1' > {elem.name}</h4>
                              <div>
                              <i className="fa-solid fa-pen-to-square edit-btn" title='Edit item' onClick={()=>{editItem(elem.id)}}></i>
                              <i className="fa-solid fa-trash text-danger" title='Delete item' onClick={()=>{deleteItem(elem.id)}}></i>
                              </div>
                            
                           </div>
                        );
                    })
                }
                
            </div>
            <div className='remove-btn'>
                 <button className='mt-3 d-block mx-auto ' onClick={removeAll} data-sm-link-text="remove all">Remove List</button>
            </div>

        </div>
    </div>
  )
}

export default TodoList;