import React from 'react'
// import { NavLink } from "react-router-dom";

const FoodCard = (props) => {
  return (
  <div className="col-md-4 col-sm-6 col-xs-12">
     <div className='food_details'>
        <div className='food_img'>
            <img src={props.imgsrc} alt="food" />
        </div>
        <div className='food_content'>
            <h2>{props.name}</h2>
            <p>{props.description}</p>
            <span>{props.price}</span> 
        </div>
     </div>    
  </div>
  )
}

export default FoodCard