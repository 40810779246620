import React from "react";
import { NavLink } from "react-router-dom";

const Card = (props) => {
  return (
    <div className="col-md-3 col-sm-6 col-xs-12">
      <div className="card" style={{ width: "18rem",margin:"0 auto" }}>
        <img
          src={props.imgsrc}
          className="card-img-top"
          alt="simpleCard"
        />
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">
           {props.BodyContent}
          </p>
          <NavLink to={props.visite} className="btn btn-primary">
            {props.btname}
          </NavLink>
        </div>
      </div>
    </div>
  );
};
export default Card;
