import React, { useState } from 'react'
import MenuList , {categories} from './MenuList';
import FoodCard from './FoodCard';
import Button from '@mui/material/Button';
import "./Tab.css"

const Food = () => {
    const [items, setitems]=useState(MenuList);
   
      const filterItem = (catItem) =>{
       const updatedItem = MenuList.filter((curElem)=>{
          return curElem.category === catItem;
       }) 
       console.log(updatedItem)
       setitems(updatedItem)
    } 
  return (
    <section>
      <div className="container ">
        <div>
          <div className="my-5 ">
            <h2 className="text-center">Your Favraite Dish</h2>
          </div>
          <div className='manu-tabs mb-3'>
            <div className='menu-tab d-flex justify-content-around'>
            {
                categories.map((elem ,index)=>{
                    return(
                      <Button variant="contained" key={index} onClick={()=>{filterItem(elem.category)}} >{elem.category}</Button>
                        
                    )
                })
            }
            <button type="button" className="btn btn-danger" onClick={()=>{setitems(MenuList)}}>All</button>
            </div>
          </div>
        </div>
        <div className="card_gallery">
          <div className="row gy-5">
          {
            items.map((val , index)=>{
            return(
                <FoodCard name={val.name} key={val.id} imgsrc={val.imgsrc} category={val.category} description={val.description} price={val.price}  />
             );
            })
          }
          </div>
        </div>
      </div>
    </section>
  )
}

export default Food