import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
// import ReactTooltip from 'react-tooltip'
import "../index.css";

const Header = () => {
  const [ name , setName ] = useState("");
  // const classNameFunc = ({ isActive }) => (isActive ? "active_link" : "");
 useEffect(() => {
  const username = localStorage.getItem("user");
  setName(username)
 },[name]) 

  const logout = () =>{
    setName("")
    localStorage.removeItem('user');
  }
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <NavLink
            // activeClassName="menu_active"
            to="/"
            className="navbar-brand"
          >
            Shrmnoday
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse " id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink
                  
                  // activeClassName={classNameFunc}
                  to="/"
                  className="nav-link active"
                  aria-current="page"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  // activeClassName={classNameFunc}
                  
                  to="/about"
                  className="nav-link"
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  // activeClassName={classNameFunc}
                  to="/weather"
                  className="nav-link"
                >
                  Weather
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  // activeClassName={classNameFunc}
                  to="/notepad"
                  className="nav-link"
                >
                  Notepad
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  // activeClassName={classNameFunc}
                  
                  to="/foods"
                  className="nav-link"
                >
                  Foods
                </NavLink>
              </li>
              
              {name ? (
                <>
                <li className="nav-item">
                <NavLink
                  // activeClassName={classNameFunc}
                  to="/service"
                  className="nav-link"
                >
            
                 <div className="img_box">
                 {/* <Avatar alt="Travis Howard" src=" " />{name} */}
                 <Chip
                    
                    avatar={<Avatar alt="Natacha" src="https://cdn3.iconfinder.com/data/icons/avatars-round-flat/33/man5-512.png" />}
                    label= {name}
                    variant="outlined"
                  />
                 {/* import ReactTooltip from 'react-tooltip' */}

                {/* <p ref= { ref => fooRef = ref} data-tip='tooltip'></p>
                <button onClick={() => { ReactTooltip.show(fooRef) }}></button>
                <ReactTooltip /> */}
                
                 </div>   
                 
                </NavLink>
               </li>
                  <li className="nav-item">
                  <NavLink
                      // activeClassName={classNameFunc}
                      
                      to="/"
                      className="nav-link"
                      onClick={logout}
                    >
                      logout
                    </NavLink>
                  </li>
                  
                </>
              ) : (
                <>
                <li className="nav-item">
                  <NavLink
                    // activeClassName={classNameFunc}
                    
                    to="/login"
                    className="nav-link"
                  >
                    Login
                  </NavLink>
                </li>
                <li className="nav-item">
                <NavLink
                  // activeClassName={classNameFunc}
                  
                  to="/contract"
                  className="nav-link"
                >
                  Sign up
                </NavLink>
              </li>
              </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Header;

// logout
// https://www.youtube.com/watch?v=P0U31KG3EuU
