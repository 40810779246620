import React from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
import Service from './Components/Service';
import Contract from './Components/Contract';
import Weather from './Components/weather/Weather';
import TodoList from './Components/todo_list/TodoList';
import Food from './Components/Food/Food';
import Login from './Components/LoginAndSignin/Login';

// import "./index.css"

function App() {
 return(
  <>
    <Header/>
    <Routes>
      <Route  exact path="/" element={<Home/>} />
      <Route  exact path="/about" element={<About/>} />
      <Route  exact path="/service" element={<Service/>} />
      <Route  exact path="/contract" element={<Contract/>} />
      <Route  exact path="/weather" element={<Weather/>} />
      <Route  exact path="/notepad" element={<TodoList/>} />
      <Route  exact path="/foods" element={<Food/>} />
      <Route  exact path="/login" element={<Login/>} />
    </Routes> 
    <Footer/>
  </>
 );
}
export default App;
